<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid">
    <div class="col-3">
      <div class="card">
        <h5 style="color: red">
         <marquee ><b><u> Instructions</u></b></marquee> 
        </h5>
        <hr />
        <b>1] Enter your valid email ID which registered with MAHAJYOTI.</b>
        <br />
        <hr />
        <b>
          2] Enter your User ID (Mobile Number) which registered with
          MAHAJYOTI.</b
        >
        <br />
        <hr />
        <b> 3] All Test are for One hour open for Two hours.</b>
        <br />
        <hr />
        <b> 4] Click on the subject name to View Practice Test.</b>
        <br />
        <hr />
        <b style="color: red">
          5] Mathematics 10am to 12pm (Test for 1 Hour).</b
        >
        <br />
        <hr />
        <b style="color: red"> 6] Physics 12pm to 02pm (Test for 1 Hour).</b>
        <br />
        <hr />
        <b style="color: red"> 7] Chemistry 02pm to 04pm (Test for 1 Hour).</b>
        <br />
        <hr />
        <b style="color: red"> 8] Botany 04pm to 06pm (Test for 1 Hour).</b>
        <br />
        <hr />
        <b style="color: red"> 9] Zoology 06pm to 08pm (Test for 1 Hour).</b>
        <br />
        <hr />
        <b> 10] All question are compulsory.</b>
        <br />
        <hr />
        <b> 11] Result will be published on Tuesday on same Portal.</b>
      </div>
    </div>
    <div class="col-9">
      <div class="card">
        <Toast />
        <div class="grid">
          <div class="col-2">
            <Dropdown
              id="status"
              v-model="submissionstatus"
              :options="submissionItems"
              :value="submissionItems.value"
              optionLabel="name"
              placeholder="Select Year"
              class="my-select mr-2"
              required="true"
              v-on:change="get_list"
            ></Dropdown>
          </div>
          <div class="col-10">
            <Button
            v-if="Mathematics_Glink"
              label="Math"
              class="p-button-rounded p-button-secondary"
              @click="view_link(Mathematics_Glink)"
            />&nbsp;
            <Button
            v-if="Physics_Glink"
              label="Physics"
              class="p-button-rounded p-button-success"
              @click="view_link(Physics_Glink)"
            />&nbsp;
            <Button
            v-if="Chemistry_Glink"
              label="Chemistry"
              class="p-button-rounded p-button-info"
              @click="view_link(Chemistry_Glink)"
            />&nbsp;
            <Button
            v-if="Botany_Glink"
              label="Botany"
              class="p-button-rounded p-button-warning"
              @click="view_link(Botany_Glink)"
            />&nbsp;
            <Button
            v-if="Zoology_Glink"
              label="Zoology"
              class="p-button-rounded p-button-danger"
              @click="view_link(Zoology_Glink)"
            />
          </div>
        </div>
      </div>
      <div class="card">
      
              <iframe :src=Link width="100%" height="1000px" frameborder="0"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      Mathematics_Glink: "",
      Physics_Glink: "",
      Chemistry_Glink: "",
      Botany_Glink: "",
      Zoology_Glink: "",
      Link:"",

      ask_again: null,
      ask_q: false,
      TQAsk: 0,
      TQAnswer: 0,
      TNL: 0,
      uploadproductDialog: false,
      stdDialog: false,
      submissionItems: [],
      submissionstatus: { name: "", value: "" },

      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,

      dropdownItems: [
        { name: "YES", value: "YES" },
        { name: "NO", value: "NO" },
      ],

      stdproducts: null,
      products: null,
      products2: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      viewans: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },
      selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_submission_dropdown();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.products2 = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
  async  view_link(data)
    {this.isLoadingModel=true;
    await new Promise(resolve => setTimeout(resolve, 3000));
        
        this.Link=data;

        this.isLoadingModel=false;
    },
    viewanswer(info) {
      console.log(info);
      // this.viewans={...data};
      // this.productDialog=true;
      var data = {
        MJAD_ID: info.mjaD_ID,
      };
      this.loading = true;
      var promise = apis.viewdoubt(data);
      promise.then((response) => {
        this.loading = false;
        this.viewans = response.data.askdoubt[0];
        this.ask_again = response.data.askdoubtagain;
        this.productDialog = true;
      });
    },
    view_video(video) {
      // alert(video)
      window.open(video.data.embeD_LINK, "_blank");
    },
    view_std(info) {
      var data = {
        TSCH_DE_ID: info.tscH_DE_ID,
      };
      this.loading = true;
      var promise = apis.lectureattendedstudentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.stdproducts = response.data;
        this.stdDialog = true;
      });
    },
    get_submission_dropdown: function () {
      var data = {
        //  "TEACHER_ID":parseInt(localStorage.getItem("id"))
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.yearlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.submissionItems = [];
          for (var i = 0; i < info.length; i++) {
            this.submissionItems.push({
              name: info[i].exaM_YEAR,
              value: info[i].enrollmenT_YEAR,
            });
          }
        }
      });
    },
    //a simple date formatting function
    dateFormat(inputDate, info) {},

    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      if (this.submissionstatus.value) {
        var data = {
          //id:parseInt(localStorage.getItem("id")),

          year: this.submissionstatus.value,
        };
        this.loading = true;
        var promise = apis.practicepaperslist(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.totalRecords = 0;
          //alert(this.totalRecords);
        });
      }
    },
    refresh() {
      this.product = null;
      this.submissionstatus = { name: "", value: "" };
    },
    async get_list() {
      if (this.submissionstatus.value) {
        this.Link="";
         this.Mathematics_Glink= "";
      this.Physics_Glink= "";
      this.Chemistry_Glink= "";
      this.Botany_Glink= "";
      this.Zoology_Glink= "";

        var data = {
          year: this.submissionstatus.value,
          // Id: 181,
        };
        this.isLoadingModel = true;
        var promise = apis.practicepaperslist(data, this.id);
        promise.then((response) => {
          this.isLoadingModel = false;

          this.products = response.data;

          if (response.data) {
            for (let Z = 0; Z < response.data.length; Z++) {
              if (response.data[Z].subject == "Mathematics") {
                this.Mathematics_Glink = response.data[Z].glink;
              }
              if (response.data[Z].subject == "Physics") {
                this.Physics_Glink = response.data[Z].glink;
              }
              if (response.data[Z].subject == "Chemistry") {
                this.Chemistry_Glink = response.data[Z].glink;
              }
              if (response.data[Z].subject == "Botany") {
                this.Botany_Glink = response.data[Z].glink;
              }
              if (response.data[Z].subject == "Zoology") {
                this.Zoology_Glink = response.data[Z].glink;
              }
            }
          }

          console.log(this.products[0]);
          if (this.columns.length == 0) {
            if (this.products.length > 0) {
              this.columns = Object.keys(this.products[0]);
            }
          }
        });
        await this.get_count();
      }
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async Updatesubmission() {
      this.submitted = true;
      if (!this.selectstatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please select check by teacher",
          life: 3000,
        });
        return false;
      }
      if (!this.product.teacheR_REMARK) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Remark",
          life: 3000,
        });
        return false;
      }

      //edit
      if (this.product.tscH_DE_ID) {
        var data = {
          TSCH_DE_ID: this.product.tscH_DE_ID,
          STUDENT_ID: this.product.studenT_ID,
          TEACHER_REMARK: this.product.teacheR_REMARK,
          TEACHER_CHECK: this.selectstatus.value,
        };
        this.isLoadingModel = true;
        var promise = apis.updateteacherremark(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
      // this.selectstatus.value = this.product.type;
      // this.selectstatus.name = this.product.type;

      // this.selectstatus2.value = this.product.status;
      // this.selectstatus2.name = this.product.status;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteVideo() {
      if (this.product.id) {
        var data = {
          Id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.deletebatchmaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
.my-select {
 min-width: 8em;
}
</style>
